import * as React from "react"
import { Link } from "gatsby"
import { Newsletter } from "./newsletter"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <hr />
      <Newsletter />
      <hr />
      <footer>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/alex_lobera"
        >
          twitter
        </a>
        {` `} | {` `}
        <a
          target="_blank"
          href="https://github.com/alexlobera/"
          rel="noopener noreferrer"
        >
          github
        </a>
        {` `} | {` `}
        <a
          target="_blank"
          href="https://alexlobera.com/rss.xml"
          rel="noopener noreferrer"
        >
          rss
        </a>
        {` `} | {` `} © {new Date().getFullYear()} -{" "}
        <strong>Alex Lobera</strong>
      </footer>
    </div>
  )
}

export default Layout
