import React, { useState } from "react"
import { Link } from "gatsby"

import { addContact } from "../api"
import { Button } from "./button"

type Status = "sending" | "sent" | undefined

export function Newsletter() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState()
  const [status, setStatus] = useState<Status>()

  const loading = status === "sending"
  const sent = status === "sent"

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        setError(undefined)
        try {
          setStatus("sending")
          const response = await addContact({ email, name })
          const { status } = response
          if (status === 200) {
            setStatus("sent")
          } else if (status === 400) {
            throw new Error(await response.text())
          } else {
            throw new Error("Oops, there was an error. Please try again.")
          }
        } catch (err) {
          setError(err.message ?? err)
          setStatus(undefined)
        }
      }}
      className="news__form"
    >
      <h3>Subscribe to my newsletter</h3>
      {sent ? (
        "Thanks for subscribing"
      ) : (
        <>
          <div className="news__email">
            <>
              <input
                style={{ flex: 4 }}
                required
                type="name"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Enter your name"
              />
              <input
                style={{ flex: 5 }}
                required
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
              <Button style={{ flex: 2 }} loading={loading}>
                Subscribe
              </Button>
            </>
          </div>
          {error ? <div className="error-message">{error}</div> : null}I won’t
          send you spam. <Link to="/unsubscribe">Unsubscribe</Link> at any time.
        </>
      )}
    </form>
  )
}
